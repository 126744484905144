import React from "react";
import { RichText } from "prismic-reactjs";

import { Container } from "react-bootstrap";

import AnchorPrismic from "../elements/AnchorPrismic";
import { headingLevel } from "../../helpers";
import { ArrowDownBig } from "../../helpers/Icons";

import "../../styles/components/slices/heading-text.scss";

const HeadingText = ({ slice }) => {
  if (!slice?.primary) return null;

  const { slice_type: type, slice_label: label } = slice;
  const {
    title,
    paragraph,
    enable_box_background: enableBoxBackground,
    show_title_arrow: showTitleArrow,
  } = slice.primary;

  const containerClasslist = [
    ...(label === "small_width" ? ["mw-800"] : []),
    ...(enableBoxBackground ? ["p-2", "shadow"] : []),
  ].join(" ");

  const wrapperClasslist = [
    "text-center",
    ...(enableBoxBackground
      ? ["py-5", "py-lg-6", "px-2", "px-md-5", "px-lg-6", "bg-light"]
      : []),
  ].join(" ");

  return (
    <article
      className={`heading-text mb-6${enableBoxBackground ? " px-3" : ""}`}
      data-type={type}
    >
      <Container className={containerClasslist} fluid={label === "full_width"}>
        <div className={wrapperClasslist}>
          {title.richText?.length > 0 && (
            <header className="mb-3">
              <h2
                className={`h${headingLevel(title)} mb-3 text-primary`}
                dangerouslySetInnerHTML={{ __html: title.text }}
              />
              {showTitleArrow && <ArrowDownBig size={58} />}
            </header>
          )}
          <RichText
            render={paragraph.richText}
            serializeHyperlink={AnchorPrismic}
          />
        </div>
      </Container>
    </article>
  );
};

export default HeadingText;
