import React from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";

const ContactForm = ({ slice }) => {
  return (
    <Container
      as="article"
      className="slice-contact-form mw-800 mb-6"
      data-type={slice.slice_type}
    >
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="contactForm.Name">
              <Form.Label className="visually-hidden">Το όνομά σου*</Form.Label>
              <Form.Control type="text" placeholder="Το όνομά σου*" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="contactForm.Email">
              <Form.Label className="visually-hidden">Το email σου*</Form.Label>
              <Form.Control type="email" placeholder="Το email σου*" />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="contactForm.Tel">
          <Form.Label className="visually-hidden">Το τηλέφωνό σου</Form.Label>
          <Form.Control type="tel" placeholder="Το email σου" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="contactForm.Message">
          <Form.Label className="visually-hidden">Το μήνυμά σου*</Form.Label>
          <Form.Control as="textarea" rows={4} placeholder="Το μήνυμά σου*" />
        </Form.Group>
        <div className="text-center">
          <Button type="submit">ΑΠΟΣΤΟΛΗ</Button>
        </div>
      </Form>
    </Container>
  );
};

export default ContactForm;
