import React from "react";

import { Accordion, Container } from "react-bootstrap";

import { RichText } from "prismic-reactjs";
import AnchorPrismic from "../elements/AnchorPrismic";

const Faq = ({ slice }) => {
  const { primary, items } = slice;

  const faqItems = items.map((faq, index) => (
    <Accordion.Item eventKey={index} key={faq.question}>
      <Accordion.Header>{faq.question}</Accordion.Header>
      <Accordion.Body>
        <RichText
          render={faq.answer.richText}
          serializeHyperlink={AnchorPrismic}
        />
      </Accordion.Body>
    </Accordion.Item>
  ));

  return (
    <article className="section-faq mb-6" data-type={slice.slice_type}>
      <Container className="mw-800">
        {primary.faq_title && <h2 className="h4 mb-3">{primary.faq_title}</h2>}
        <Accordion defaultActiveKey="0">{faqItems}</Accordion>
      </Container>
    </article>
  );
};

export default Faq;
