import React from "react";

import { Button, Container } from "react-bootstrap";

import Anchor from "../elements/Anchor";

const Buttons = ({ slice }) => {
  if (!slice) return null;

  const { slice_type: type, primary, items } = slice;

  const { align } = primary;

  const buttons = items.map((button) => (
    <Button
      key={button.label}
      as={Anchor}
      variant={button.type}
      className="me-3 mb-3"
      href={button.link.url}
      target={button.link.target}
    >
      {button.label}
    </Button>
  ));

  return (
    <Container
      className={`slice-buttons mb-6 d-flex justify-content-${align} flex-wrap`}
      data-type={type}
    >
      {buttons}
    </Container>
  );
};

export default Buttons;
