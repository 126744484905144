import React from "react";
import { RichText } from "prismic-reactjs";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";

const HeroCallToAction = ({ slice }) => {
  const {
    image,
    title,
    paragraph,
    button_label: buttonLabel,
    button_link: buttonLink,
  } = slice.primary;

  return (
    <article
      className="hero-cta position-relative min-vh-100 mb-6 text-center overflow-hidden"
      data-type={slice.slice_type}
    >
      {image?.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt || ""}
          className="gatsby-background-image-element bg-light"
        />
      )}
      <Container fluid="xxl" className="text-white">
        <h2
          className="display-5 fw-bold pt-6"
          dangerouslySetInnerHTML={{ __html: title.text }}
        />
        <RichText
          render={paragraph.richText}
          serializeHyperlink={AnchorPrismic}
        />
        {buttonLink?.url && (
          <Button as={Anchor} href={buttonLink.url} target={buttonLink.target}>
            {buttonLabel}
          </Button>
        )}
      </Container>
    </article>
  );
};

export default HeroCallToAction;
