import React from "react";
import { RichText } from "prismic-reactjs";

import { Container } from "react-bootstrap";

import AnchorPrismic from "../elements/AnchorPrismic";

import "../../styles/components/slices/text.scss";

const Text = ({ slice }) => {
  if (!slice && slice.primary?.text?.richText?.length < 1) return null;

  const { slice_type: type, slice_label: label, primary } = slice;
  const plabel = label ? label.replace("_", "-") : "";

  return (
    <Container className={`slice-text mw-800 mb-6 ${plabel}`} data-type={type}>
      <RichText
        render={primary.text.richText}
        serializeHyperlink={AnchorPrismic}
      />
    </Container>
  );
};

export default Text;
