import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";
import { headingLevel } from "../../helpers";

const ImagewithContent = ({ slice }) => {
  if (!slice?.primary) return null;

  const {
    title,
    paragraph,
    image,
    button_link: buttonLink,
    button_label: buttonLabel,
    image_right: imageRight,
  } = slice.primary;

  return (
    <article className="image-content mb-6" data-type={slice.slice_type}>
      <Container className="px-xl-7 px-xxl-10">
        <Row>
          {image?.gatsbyImageData && (
            <Col md={{ span: 7, order: imageRight ? "last" : null }}>
              <GatsbyImage
                image={image.gatsbyImageData}
                className="mb-5 mb-md-0"
                alt={image.alt}
              />
            </Col>
          )}
          <Col md={5}>
            {title?.richText?.length > 0 && (
              <h2 className={`h${headingLevel(title)} text-primary`}>
                {title.text}
              </h2>
            )}
            <RichText
              render={paragraph.richText}
              serializeHyperlink={AnchorPrismic}
            />
            {buttonLink?.url && (
              <Button
                as={Anchor}
                href={buttonLink.url}
                target={buttonLink.target}
              >
                {buttonLabel}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default ImagewithContent;
