import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import Icon from "../elements/Icon";

import { headingLevel } from "../../helpers";
import { ArrowDownBig } from "../../helpers/Icons";

const Features = ({ slice }) => {
  if (slice?.primary?.title?.text === "" && slice?.items?.length < 1)
    return null;

  const { title } = slice.primary;

  const list = slice.items.map((item) => (
    <Col md={4} key={item.heading}>
      <section className="features__item d-flex flex-column align-items-center h-100 px-3 text-center">
        <Icon icon={item.icon} className="flex-fill py-4" />
        {item.heading && <h3 className="h6 mb-0">{item.heading}</h3>}
        {item.paragraph && <p>{item.paragraph}</p>}
      </section>
    </Col>
  ));

  return (
    <article className="section-features mb-6" data-type={slice.slice_type}>
      <Container>
        {title.text !== "" && (
          <header className="pb-5 text-center">
            <h2
              className={`display-${headingLevel(title)} fw-normal mb-0`}
              dangerouslySetInnerHTML={{ __html: title.text }}
            />
            <ArrowDownBig />
          </header>
        )}
        {list.length > 0 && <Row>{list}</Row>}
      </Container>
    </article>
  );
};

export default Features;
