import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Col, Container, Row } from "react-bootstrap";
import { headingLevel } from "../../helpers";
import { ArrowDownBig } from "../../helpers/Icons";
import Icon from "../elements/Icon";

import "../../styles/components/slices/items-background.scss";

const ItemsWithBackground = ({ slice }) => {
  if (!slice?.primary) return null;

  const { title, background_image: bg } = slice.primary;

  const list = slice.items.map((item) => (
    <Col sm={6} md={3} key={item.heading}>
      <section className="d-flex flex-column align-items-center px-2 text-center">
        <Icon icon={item.icon} className="pb-2" />
        <h3 className="h6">{item.heading}</h3>
      </section>
    </Col>
  ));

  return (
    <article
      className="section-items-background mb-6"
      data-type={slice.slice_type}
    >
      <Container fluid="xxl">
        <div className="position-relative p-5 p-md-6">
          {bg?.gatsbyImageData && (
            <GatsbyImage
              image={bg.gatsbyImageData}
              className="gatsby-background-image-element"
              alt={bg.alt || ""}
            />
          )}
          <div
            className="py-5 py-md-7 px-3 bg-white"
            style={{ "--bs-bg-opacity": "0.9" }}
          >
            {title.text !== "" && (
              <header className="pb-5 text-center">
                <h2
                  className={`display-${headingLevel(title)} fw-normal mb-0`}
                  dangerouslySetInnerHTML={{ __html: title.text }}
                />
                <ArrowDownBig />
              </header>
            )}
            {list.length > 0 && <Row>{list}</Row>}
          </div>
        </div>
      </Container>
    </article>
  );
};

export default ItemsWithBackground;
