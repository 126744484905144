import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container } from "react-bootstrap";

const Image = ({ slice }) => {
  if (!slice?.primary?.image?.gatsbyImageData) return null;

  const { image, width } = slice.primary;

  return (
    <Container
      as="figure"
      fluid={width}
      className={`image-slice mb-6 text-center${width ? " px-0" : ""}`}
      data-type={slice.slice_type}
    >
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.alt || ""}
        className={width ? "gatsby-image-constrained--full" : ""}
      />
    </Container>
  );
};

export default Image;
