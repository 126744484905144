import React from "react";
import {
  HeroCallToAction,
  HeadingText,
  Features,
  ItemsWithBackground,
  ImagewithContent,
  Image,
  Text,
  Buttons,
  Faq,
  ContactForm,
} from "../slices";

const SliceZone = ({ slices }) => {
  const sliceComponents = {
    call_to_action: HeroCallToAction,
    heading_with_text: HeadingText,
    features: Features,
    items_with_background_image: ItemsWithBackground,
    image_with_content: ImagewithContent,
    image: Image,
    text: Text,
    buttons: Buttons,
    faq: Faq,
    contact_form: ContactForm,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    const key = slice.id + index;

    if (SliceComponent) {
      return <SliceComponent slice={slice} key={key} />;
    }

    return null;
  });
};

export default SliceZone;
